import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    ParaPoint: '⟶',
    ParaOneLeft: "As a SaaS platform technology, Raindrop’s Enterprise Spend Management solution is a perfect example of instant value attainment delivered in the cloud.  According to",
    ParaOneAnchor: "Forbes,",
    ShiftUrlParaOne: "https://www.forbes.com/sites/theyec/2021/01/19/what-to-expect-from-the-rapidly-growing-and-evolving-software-as-a-service-market-in-2021/?sh=31df87027300",
    ParaOneRight: "the impact of Covid and the rise of the remote worker has rapidly accelerated the move to SaaS.  While this is not a surprise, the value and ease that each customer realizes has been a boon to the industry.  For example, suppose a buyer is looking to take their business out to market via what is generally referred to as “e-sourcing” technology. In that case, they can go from having no technology solution, to a complete production instance within a minute.  This rapid access to value was previously unheard of.",
    ParaTwo: "What does this mean to you? Raindrop’s Sourcing technology is a key component in reducing your corporate expense run rate by rapidly and easily taking your supplier spend out to the market and increasing the competition for your money.  Since cost considerations are a vital driver for every company, Raindrop helps reduce your costs and expand your margin.",
    HeadingOne: "How Raindrop’s Sourcing Technology Functions to Maximize Business Profitability?",
    ParaThree: "Raindrop’s Sourcing technology allows you to quickly go out to market for any good and service via an easy-to-use interface.  Quickly start by easy onboarding your internal team, write up a project summary, invite your desired bidding suppliers, and enjoy the efficiencies of price concessions during online Reverse Auctions or rounds of bids in any RFx event.  It’s that easy and intuitive.",
    ParaFour: "Raindrop’s rapid value attainment can be realized in literal minutes from the moment your Production instance is acquired.  Simply begin any Sourcing event, and gain access to cost reductions.  Raindrop’s capabilities to run an RFI, RFQ, RFP, or a Reverse Auction are all natively built into the Sourcing Module, so value realization is instant.  As part of the broader spend management strategy, Reverse Auctions generate significant savings through competition between suppliers, as suppliers improve their position in the auction by presenting successively lower bids to earn your business. This creates room for considerable cost savings without compromising the supplier’s quality of service.",
    ParaFive: "Built-into Raindrop’s Sourcing module, the Reverse Auction feature allows the buyers and suppliers to connect to bid and sell products and services.  It helps Procurement professionals to take their requirements out to market and negotiate with a broader pool of bidders/suppliers using an efficient, technology-driven process, mitigating the need for time-consuming individual discussions, delivering negotiation transparency, and reducing final price through increased bidder/supplier competition.",
    HeadingTwo: "How Does Raindrop’s Sourcing Module Help Improve Overall Business Performance?",
    HeadingTwoPointOne: "Powerful and Improved Evaluation",
    ParaSix: "Raindrop’s Sourcing dashboard groups stakeholders involved in the RFx and makes an evaluation as simple as possible. With a centralized place to review and score proposals, be it about who has the highest or lowest price, or getting notified about deadlines, completing the evaluation, or awarding the business to a bidding supplier, all can be done with a single click or two.",
    HeadingTwoPointTwo: "Expanding and Improving Supplier Base",
    ParaSeven: "Organizations can enter global markets at reduced costs by connecting and qualifying new suppliers in the Raindrop platform. These new supplier resources can deliver your required goods and services in a way that was challenging a decade ago and simply not possible a generation ago.  Businesses can also be more responsive and dynamic by using Raindrop’s real-time collaboration technology natively built into the Sourcing platform to communicate and gain instant data availability to identify market shifts in commercial terms and conditions.",
    HeadingTwoPointThree: "Digitalization of Reverse Auction Platform",
    ParaEight: "Raindrop’s Sourcing event dashboard lets buyers know the price and quality of similar products across the bidder/supplier base.  All the bids are submitted in a common format and included within a single panel.  Raindrop’s inbuilt AI and ML capabilities make it easy for buyers to effectively tap into the global supplier base.",
    HeadingTwoPointFour: "Faster Access to Market Insights",
    ParaNine: "With Raindrop, you can take your sourcing potential to a new level by getting informed on your commodity's market price.  With relevant and precise insights into commodity pricing, never overpay for any of your procured items. Raindrop aids your negotiations with incumbent and net-new suppliers to attain desired products/services at reduced costs to ensure you reach a reasonable market rate.",
    HeadingTwoPointFive: "Wrap Up",
    ParaTen: "Built-in with extensive features and emphasizing an ease-of-use interface, Raindrop’s intuitive platform makes it easy to uplift your business performance by helping organizations uncover new sourcing opportunities, optimize decision-making, and drive sustainable savings, all instantly delivered by Raindrop’s SaaS technology.  That’s the power of Raindrop.",
  },
]

import React from 'react'
import { AppResourcesEmailBanner } from './AppResourcesEmailBanner'
import CovidBannerData from '../Data/CovidBannerData.json'
import styled from 'styled-components'

const AppCovidBannerWapper = styled.div`
  .CovidNeedsSection {
    display: flex;
    justify-content: space-between;
    padding: 130px 50px 50px 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 80px 15px 10px 15px;
    }
  }
  .CovidImageSection {
    @media (max-width: 1090px) {
      margin: auto;
      padding-top: 30px;
    }
  }
  .CovidHeading {
    max-width: 850px;
    font-size: 2.5rem;
    line-height: 1.30435em;
    padding: 0px 30px 0px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
      max-width: unset;
    }
  }
  .CovidPara {
    max-width: 850px;
    padding: 15px 30px 15px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
      max-width: unset;
    }
  }
  .CovidDatePara {
    max-width: 850px;
    color: #34a1d5;
    font-size: 19px;
    padding: 15px 30px 0px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
    }
  }
  .CovidBannerImage {
    width: 100%;
    height: auto;
    box-shadow: rgb(160 161 163 / 88%) 0px 3px 7px 0px;
    border-radius: 18px;
  }
  .DisplayNone {
    display: none;
  }
`

export const AppCovidBanner = () => {
  return (
    <AppCovidBannerWapper>
      {CovidBannerData.map((data, index) => {
        return (
          <div key={index}>
            <AppResourcesEmailBanner
              Heading={data.Title}
              Para={data.Leadpara}
              DatePara={data.DatePara}
              ImagePath={data.ImagePath}
              AltText={data.AltText}
              LeadParaStyle='DisplayNone'
              BannerHeading='CovidHeading'
              BannerPara='CovidPara'
              DateBannerPara='CovidDatePara'
              BannerImageSection='CovidImageSection'
              BannerImage='CovidBannerImage'
              BannerSection='CovidNeedsSection'
            />
          </div>
        )
      })}
    </AppCovidBannerWapper>
  )
}
